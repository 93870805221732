@tailwind base;
@tailwind components;
@tailwind utilities;
@layer base {
  html {
    height: 100%;
  }
  html,
  body {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    flex: 1 1 0;
  }
  body {
    font-family: Roboto;
  }
  .component,
  ng-component,
  router-outlet {
    display: contents !important;
  }
}
@layer components {
  .dark-shimmer {
    @apply shimmer-accent-primary-extra-extra-lighter shimmer-neutral-lighter;
  }
}
/* devexpress data-grid */
.dx-datagrid .dx-column-lines > td {
  border: none !important;
}

.dx-datagrid-headers {
  @apply text-label-2 !important;
  @apply font-bold !important;
  @apply py-4 !important;
  @apply text-black !important;
}

.dx-datagrid-headers .dx-datagrid-table .dx-row > td {
  border-bottom: none !important;
}

.dx-datagrid-borders .dx-datagrid-rowsview,
.dx-datagrid-headers + .dx-datagrid-rowsview {
  border-top: none !important;
}

.dx-datagrid-content .dx-datagrid-table .dx-row > td,
.dx-datagrid-content .dx-datagrid-table .dx-row > tr > td {
  vertical-align: inherit !important;
}

td {
  @apply px-12 !important;
}

.dx-header-row td {
  @apply py-10 !important;
}

.dx-datagrid-nodata {
  @apply text-black !important;
  @apply font-paragraph-2 !important;
  @apply text-paragraph-2 !important;
}

#cloudpayments-loading-overlay-63456123 > img {
  left: 50% !important;
}

@layer base {
  @font-face {
    font-family: Styrene;
    font-weight: 700;
    /*noinspection CssUnknownTarget*/
    src: url(/fonts/StyreneA-Bold-Cy-Web.woff) format("woff");
  }
  @font-face {
    font-family: Styrene;
    font-weight: 400;
    /*noinspection CssUnknownTarget*/
    src: url(/fonts/StyreneA-Regular-Cy-Web.woff) format("woff");
  }
  @font-face {
    font-family: Styrene;
    font-weight: 300;
    /*noinspection CssUnknownTarget*/
    src: url(/fonts/StyreneA-Light-Cy-Web.woff) format("woff");
  }
  @font-face {
    font-family: Roboto;
    font-weight: 900;
    /*noinspection CssUnknownTarget*/
    src: url(/fonts/Roboto-Black.ttf) format("truetype");
  }
  @font-face {
    font-family: Roboto;
    font-weight: 700;
    /*noinspection CssUnknownTarget*/
    src: url(/fonts/Roboto-Bold.ttf) format("truetype");
  }
  @font-face {
    font-family: Roboto;
    font-weight: 500;
    /*noinspection CssUnknownTarget*/
    src: url(/fonts/Roboto-Medium.ttf) format("truetype");
  }
  @font-face {
    font-family: Roboto;
    font-weight: 400;
    /*noinspection CssUnknownTarget*/
    src: url(/fonts/Roboto-Regular.ttf) format("truetype");
  }
  @font-face {
    font-family: Roboto;
    font-weight: 300;
    /*noinspection CssUnknownTarget*/
    src: url(/fonts/Roboto-Light.ttf) format("truetype");
  }
  @font-face {
    font-family: Roboto;
    font-weight: 100;
    /*noinspection CssUnknownTarget*/
    src: url(/fonts/Roboto-Thin.ttf) format("truetype");
  }
  @font-face {
    font-family: Roboto;
    font-weight: 900;
    font-style: italic;
    /*noinspection CssUnknownTarget*/
    src: url(/fonts/Roboto-BlackItalic.ttf) format("truetype");
  }
  @font-face {
    font-family: Roboto;
    font-weight: 700;
    font-style: italic;
    /*noinspection CssUnknownTarget*/
    src: url(/fonts/Roboto-BoldItalic.ttf) format("truetype");
  }
  @font-face {
    font-family: Roboto;
    font-weight: 500;
    font-style: italic;
    /*noinspection CssUnknownTarget*/
    src: url(/fonts/Roboto-MediumItalic.ttf) format("truetype");
  }
  @font-face {
    font-family: Roboto;
    font-weight: 400;
    font-style: italic;
    /*noinspection CssUnknownTarget*/
    src: url(/fonts/Roboto-RegularItalic.ttf) format("truetype");
  }
  @font-face {
    font-family: Roboto;
    font-weight: 300;
    font-style: italic;
    /*noinspection CssUnknownTarget*/
    src: url(/fonts/Roboto-LightItalic.ttf) format("truetype");
  }
  @font-face {
    font-family: Roboto;
    font-weight: 100;
    font-style: italic;
    /*noinspection CssUnknownTarget*/
    src: url(/fonts/Roboto-ThinItalic.ttf) format("truetype");
  }
}